@import "./mixins.scss";
@import "./initialVariables.scss";

/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */

// white Angelica //

@font-face {
  font-family: "White Angelica";
  src: url("../public/static/fonts/whiteAngelica/WhiteAngelicaRegular.woff2")
      format("woff2"),
    url("../public/static/fonts/whiteAngelica/WhiteAngelicaRegular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// garamond //

@font-face {
  font-family: "garamond";
  src: url("../public/static/fonts/garamond/GaramondBE-MediumCnOsF.woff2")
      format("woff2"),
    url("../public/static/fonts/garamond/GaramondBE-MediumCnOsF.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "garamond";
  src: url("../public/static/fonts/garamond/GaramondBE-ItalicOsF.woff2")
      format("woff2"),
    url("../public/static/fonts/garamond/GaramondBE-ItalicOsF.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "garamond";
  src: url("../public/static/fonts/garamond/GaramondBE-BoldOsF.woff2")
      format("woff2"),
    url("../public/static/fonts/garamond/GaramondBE-BoldOsF.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

// interstate //

@font-face {
  font-family: "interstate";
  src: url("../public/static/fonts/interstate/interstate_cond_mono__blk_0-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/interstate/interstate_cond_mono__blk_0-webfont.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "interstate";
  src: url("../public/static/fonts/interstate/interstate_cond_mono__lgt_0-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/interstate/interstate_cond_mono__lgt_0-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "interstate";
  src: url("../public/static/fonts/interstate/interstate_cond_mono_bold_1-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/interstate/interstate_cond_mono_bold_1-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "interstate";
  src: url("../public/static/fonts/interstate/interstate_cond_mono_1-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/interstate/interstate_cond_mono_1-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

// optima //

@font-face {
  font-family: "optima";
  src: url("../public/static/fonts/optima/optima-bold-02-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/optima/optima-bold-02-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("../public/static/fonts/optima/optima-bolditalic-04-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/optima/optima-bolditalic-04-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("../public/static/fonts/optima/optima-extrablack-05-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/optima/optima-extrablack-05-webfont.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("../public/static/fonts/optima/optima-italic-03-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/optima/optima-italic-03-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("../public/static/fonts/optima/optima-regular-01-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/optima/optima-regular-01-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

// akzidenz //

@font-face {
  font-family: "akzidenzgroteskregular";
  src: url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Black.woff2")
      format("woff2"),
    url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Black.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "akzidenzgrotesk";
  src: url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Bold.woff2")
      format("woff2"),
    url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "akzidenzgrotesk";
  src: url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Light.woff2")
      format("woff2"),
    url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Light.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "akzidenzgrotesk";
  src: url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Roman.woff2")
      format("woff2"),
    url("../public/static/fonts/akzidenz/AkzidenzGrotesk-Roman.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

// Aviano Flare //

@font-face {
  font-family: "aviano-flare";
  src: url("https://use.typekit.net/af/ad7edb/000000000000000077359a9b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ad7edb/000000000000000077359a9b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ad7edb/000000000000000077359a9b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "aviano-flare";
  src: url("https://use.typekit.net/af/d8cc97/000000000000000077359aa5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d8cc97/000000000000000077359aa5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d8cc97/000000000000000077359aa5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

//blairmditc//

@font-face {
  font-family: "blairmditc";
  src: url("../public/static/fonts/blairmditc/blairmditc-tt-medium-font-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/blairmditc/blairmditc-tt-medium-font-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//mondia//

@font-face {
  font-family: "mondia";
  src: url("../public/static/fonts/mondia/mondia_regular-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/mondia/mondia_regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "mondia";
  src: url("../public/static/fonts/mondia/mondiaextrabold-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/mondia/mondiaextrabold-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

//Apercu//

@include font-face(
  "Apercu",
  "../public/static/fonts/apercu/apercu-light",
  500,
  normal,
  woff woff2
);
@include font-face(
  "Apercu",
  "../public/static/fonts/apercu/apercu-regular",
  600,
  normal,
  woff woff2
);
@include font-face(
  "Apercu",
  "../public/static/fonts/apercu/apercu-bold",
  700,
  normal,
  woff woff2
);

// playFairDisplay //

@font-face {
  font-family: "playfair";
  src: url("../public/static/fonts/playFairDisplay/playfairdisplay-variablefont_wght-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/playFairDisplay/playfairdisplay-variablefont_wght-webfont.woff2")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// playFairDisplay //

// spartan //

@font-face {
  font-family: "spartan";
  src: url("../public/static/fonts/spartan/Spartan-VariableFont_wght.ttf")
    format("ttf supports variations");
  font-weight: 100 1000;
}

// spartan //

//  CHEAP PINE //

@font-face {
  font-family: "cheap-pine-sans";
  src: url("https://use.typekit.net/af/3a6f45/00000000000000007735b425/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3a6f45/00000000000000007735b425/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3a6f45/00000000000000007735b425/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

// END CHEAP PINE //

// script //

@font-face {
  font-family: "script";
  src: url("../public/static/fonts/script/guldscript-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/script/guldscript-webfont.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// script //

// titlingGothic //

@font-face {
  font-family: "titlinggothicfb";
  src: url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-regular-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "titlinggothicfb";
  src: url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-bold-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-bold-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "titlinggothicfb";
  src: url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-light-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/titlingGothic/titlinggothicfbnormal-light-webfont.woff")
      format("woff");
  font-weight: light;
  font-style: light;
  font-display: swap;
}

// end titlingGothic //

// Sheila imported from https://use.typekit.net/tdn5lqy.css
@include font-face(
  "Sheila",
  "https://use.typekit.net/af/9b4626/00000000000000003b9b218e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3",
  400,
  normal,
  otf ttf
);
@include font-face(
  "Sheila",
  "https://use.typekit.net/af/e761e1/00000000000000003b9b218f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3",
  700,
  normal,
  otf ttf
);

// cormorant_garamond

@font-face {
  font-family: "cormorant";
  src: url("../public/static/fonts/cormorant/cormorantgaramond-light-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/cormorant/cormorantgaramond-light-webfont.woff2")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cormorant";
  src: url("../public/static/fonts/cormorant/cormorantgaramond-medium-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/cormorant/cormorantgaramond-medium-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cormorant";
  src: url("../public/static/fonts/cormorant/cormorantgaramond-bold-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/cormorant/cormorantgaramond-bold-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// sueEllenFrancisco

@font-face {
  font-family: "sueEllenFrancisco";
  src: url("../public/static/fonts/sueEllenFrancisco/sueellenfrancisco-regular-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/sueEllenFrancisco/sueellenfrancisco-regular-webfont.woff2")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// avenir

@font-face {
  font-family: "avenir";
  src: url("../public/static/fonts/avenir/avenir-medium-09-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/avenir/avenir-medium-09-webfont.woff2")
      format("woff");
  font-weight: 600;
  font-style: medium;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("../public/static/fonts/avenir/avenir-book-01-webfont.woff")
      format("woff2"),
    url("../public/static/fonts/avenir/avenir-book-01-webfont.woff2")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenirNext";
  src: url("../public/static/fonts/avenir/avenirnextcondensed-regular-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/avenir/avenirnextcondensed-regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenirNext";
  src: url("../public/static/fonts/avenir/avenirnextcondensed-ultralight-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/avenir/avenirnextcondensed-ultralight-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
// Circa Slab A //

@font-face {
  font-family: "circe-slab-a";
  src: url("https://use.typekit.net/af/18a67c/00000000000000007735cd75/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/18a67c/00000000000000007735cd75/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/18a67c/00000000000000007735cd75/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "circe-slab-a";
  src: url("https://use.typekit.net/af/5f969c/00000000000000007735cd79/30/l?subset_id=2&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5f969c/00000000000000007735cd79/30/d?subset_id=2&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5f969c/00000000000000007735cd79/30/a?subset_id=2&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "circe-slab-a";
  src: url("https://use.typekit.net/af/2feefc/00000000000000007735cd80/30/l?subset_id=2&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2feefc/00000000000000007735cd80/30/d?subset_id=2&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2feefc/00000000000000007735cd80/30/a?subset_id=2&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "circe-slab-a";
  src: url("https://use.typekit.net/af/142479/00000000000000007735cd84/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/142479/00000000000000007735cd84/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/142479/00000000000000007735cd84/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

// Gotham //

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothambold-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothambold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothambolditalic-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothambolditalic-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothamlight-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothamlightitalic-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothamlightitalic-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothammedium-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothammedium-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham";
  src: url("../public/static/fonts/gotham/gothammediumitalic-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/gotham/gothammediumitalic-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// NOVEL SANS PRO //

@font-face {
  font-family: "novel-sans-pro";
  src: url("https://use.typekit.net/af/608136/00000000000000007735c221/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/608136/00000000000000007735c221/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/608136/00000000000000007735c221/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "novel-sans-pro";
  src: url("https://use.typekit.net/af/c7201e/00000000000000007735c226/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c7201e/00000000000000007735c226/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c7201e/00000000000000007735c226/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "novel-sans-pro";
  src: url("https://use.typekit.net/af/15b3c0/00000000000000007735c247/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/15b3c0/00000000000000007735c247/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/15b3c0/00000000000000007735c247/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "novel-sans-pro";
  src: url("https://use.typekit.net/af/487ba5/00000000000000007735c24c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/487ba5/00000000000000007735c24c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/487ba5/00000000000000007735c24c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

// END NOVEL SANS PRO //

// NEWTEXT //

@font-face {
  font-family: "newtext";
  src: url("../public/static/fonts/newText/newtext_itc_std_book_italic-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/newText/newtext_itc_std_book_italic-webfont.woff")
      format("woff");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "newtext";
  src: url("../public/static/fonts/newText/newtext_itc_std_book-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/newText/newtext_itc_std_book-webfont.woff")
      format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "newtext";
  src: url("../public/static/fonts/newText/newtext_itc_std_demi-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/newText/newtext_itc_std_demi-webfont.woff")
      format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "newtext";
  src: url("../public/static/fonts/newText/newtext_itc_std_light-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/newText/newtext_itc_std_light-webfont.woff")
      format("woff");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "newtext";
  src: url("../public/static/fonts/newText/newtext_itc_std_regular-webfont.woff2")
      format("woff2"),
    url("../public/static/fonts/newText/newtext_itc_std_regular-webfont.woff")
      format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

// END NEWTEXT //

// POPPINS //

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

// END POPPINS //

// IBM PLEX SERIF //

@font-face {
  font-family: "ibm-plex-serif";
  src: url("https://use.typekit.net/af/810879/000000000000000077359444/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/810879/000000000000000077359444/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/810879/000000000000000077359444/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "ibm-plex-serif";
  src: url("https://use.typekit.net/af/f110d6/000000000000000077359459/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f110d6/000000000000000077359459/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f110d6/000000000000000077359459/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "ibm-plex-serif";
  src: url("https://use.typekit.net/af/f9d07a/000000000000000077359464/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f9d07a/000000000000000077359464/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f9d07a/000000000000000077359464/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "ibm-plex-serif";
  src: url("https://use.typekit.net/af/9e8741/000000000000000077359469/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9e8741/000000000000000077359469/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9e8741/000000000000000077359469/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

// END PLEX SERIF //

html {
  @apply tracking-wide;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

main {
}

svg {
  display: unset;
}


