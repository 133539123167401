@import "initialVariables";

@mixin respond($breakpoint) {

  @if $breakpoint==mobile-xsm {
    @media (max-width: 350px) {
      @content;
    }
  }

  @if $breakpoint==mobile-sm {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media (min-width: 576px) {
      @content;
    }
  }

  @if $breakpoint==tablet-sm {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==tablet-M {
    @media (min-width: 769px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==desktop-sm {
    @media (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==desktop-m {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==desktop-l {
    @media (min-width: 1120px) {
      @content;
    }
  }

  @if $breakpoint==desktop-lg {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==desktop-xl {
    @media (min-width: 1400px) {
      @content;
    }
  }
}

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin overlay {
  > * {
    position: relative;
    z-index: 1;
  }
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: black;
    z-index: 0;
  }
}
